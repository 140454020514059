import React from "react";
import { Link } from "react-router-dom";

const PublicCalendarPage = () => {
  // Replace 'YOUR_EMBED_CODE' with the copied embed code from Google Calendar
  const embedCode =
    '<iframe src="https://calendar.google.com/calendar/embed?height=550&wkst=1&ctz=America%2FNew_York&bgcolor=%23ffffff&showTitle=0&mode=WEEK&showCalendars=0&src=YWY5NDFmZTI5ODE5YjFiNDE1OGQ0ZWRkOWQ1OGVjMzhlZGYyZjUzMzlmNGM3NDhlODRjNTlhODVlOGUxZTQ1YUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=OGE5MzU4Y2NlZjYxODRkMzg2MDA1MDAxNzkzZDIwMDBhM2QzNWU5NDk5MDQ4NWE4NGEwYmU0Njk2ODQzMTM0YUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=YTMxMjc1YmEzMTEwMWUzOTMxOWZiMDc1ZjA5MmZmMjY3OTY0NjY4NTA5ZTM0NjBlNzRmNjU1NDJkMmUzMzg0Y0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=ZDNiNmU0OTAyZDEyNzliZWQ0NGNjY2VjYWRiZTQ0ODQ4NTAyMTY1YWYxN2JlYWI2NThjOWEzYjg1MjY3MmZkZUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=bDdoOWVzajZnbXUya2RhbXRhNjc5ZXEza2VjZnJxbW5AaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&src=dnNjMGVoc3EwZ3I3YWdzdm0wNnVvdWJpaWlndGM3YWlAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%2388bebd&color=%237986CB&color=%23E67C73&color=%23A79B8E&color=%239E69AF" style="border-width:0" width="850" height="550" frameborder="0" scrolling="no"></iframe>';

  // Replace 'YOUR_CALENDAR_SHARE_LINK' with the copied shareable link
  // const calendarBaisRabeiniShareLink =
  //   "https://calendar.google.com/calendar/u/0?cid=YWY5NDFmZTI5ODE5YjFiNDE1OGQ0ZWRkOWQ1OGVjMzhlZGYyZjUzMzlmNGM3NDhlODRjNTlhODVlOGUxZTQ1YUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t";

  // const calendarAnashShareLink =
  //   "https://calendar.google.com/calendar/u/0?cid=ZDNiNmU0OTAyZDEyNzliZWQ0NGNjY2VjYWRiZTQ0ODQ4NTAyMTY1YWYxN2JlYWI2NThjOWEzYjg1MjY3MmZkZUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t";

  return (
    <div style={{ margin: "0 auto", width: "90%", textAlign: "center" }}>
      <h1>יום סקווירא</h1>
      <h5>
        זיך איינצושרייבן צו זעהן אלע מאורעות אין אייער אייגענע גוגל קאלענדער
        דריקט אויפן לינק אונטן אויף די לינקע זייט
      </h5>
      {/* <Link to="/events">לייג צו א נייע מאורע</Link> */}
      <div
        className="responsive-iframe-container"
        dangerouslySetInnerHTML={{ __html: embedCode }}
      />
      {/* <div style={{ marginBottom: "10px" }}>
        <a
          href={calendarBaisRabeiniShareLink}
          target="_blank"
          rel="noreferrer noopener"
        >
          בית רבינו
        </a>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <a
          href={calendarAnashShareLink}
          target="_blank"
          rel="noreferrer noopener"
        >
          שמחת אנ"ש
        </a>
      </div> */}
    </div>
  );
};

export default PublicCalendarPage;
